<template>
  <main>
    <ConditionsBanner />
    <ConditionsList />
  </main>
</template>

<script>
import ConditionsBanner from "@/components/conditions/ConditionsBanner";
import ConditionsList from "@/components/conditions/ConditionsList";
export default {
  name: "ConditionsView",
  components: {
    ConditionsBanner,
    ConditionsList
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/views/_conditions";
</style>
