<template>
  <section class="conditions-banner">
    <div class="container">
      <div class="row">
        <div class="col-xx-lg-6 col-x-lg-7 col-lg-7 col-md-7 col-xs-12">
          <div @click.prevent="toPreviousPage" class="conditions-banner__back">
            Повернутися назад
          </div>
          <h1 class="conditions-banner__title">
            Правила та умови програми підтримки пацієнтів
          </h1>
          <h2 class="conditions-banner__subtitle">ТерапіяПЛЮС</h2>
          <div class="conditions-banner__icon" v-if="getResponsive">
            <img src="~@/assets/img/conditions-banner-img.png" alt="" />
          </div>
          <div class="conditions-banner__text">
            Реєструючись у Програмі, а також використовуючи картку учасника
            Програми, особа погоджується дотримуватися всіх положень цих Правил
            та Умов Програми, разом з усіма можливими наступними змінами, які
            будуть вноситись організатором Програми. Якщо особа не погоджується
            з цими Правилами та Умовами, така особа не може брати участь у
            Програмі.
          </div>
        </div>
      </div>
    </div>
    <div class="conditions-banner__img" v-if="!getResponsive"></div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ConditionsBanner",
  data() {
    return {
      previousPage: ""
    };
  },
  computed: {
    ...mapGetters(["getResponsive"])
  },
  methods: {
    toPreviousPage() {
      this.$router.go(-1);
    }
  }
};
</script>
